import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"
const Press = () => (
  <Layout>
    <h1>Press</h1>
  </Layout>
)

export default Press
